import * as S from './Logo.styled'
import { FC } from 'react'

type LogoProps = {}

export const Logo: FC<LogoProps> = () => {
  return (
    <S.Wrapper href={'/'}>
      <S.Logo />
      <S.LogoSmall />
    </S.Wrapper>
  )
}
