import styled from '@emotion/styled'

// @ts-ignore TODO David
import Close from '../../atoms/Icons/Close.svg'

export const Button = styled.button`
  display: flex;
  width: auto;
  height: auto;
  padding: 10px;
  color: ${({ theme }) => theme.colors.secondary};
  border: 0;
  outline: 0;
  background: transparent;
`

export const Icon = styled(Close)`
  width: 20px;
  height: 20px;
`
