import { ParsedLocalizations } from '@strapi/localizations'
import { FC } from 'react'
import * as S from './FooterBottom.styled'

type FooterBottomProps = {
  copyrightText?: string
  localizations: ParsedLocalizations
}

export const FooterBottom: FC<FooterBottomProps> = ({ copyrightText, localizations }) => {
  const copyrightParsed = copyrightText?.replace('{{year}}', new Date().getFullYear().toString())
  return (
    <S.Wrap>
      <S.LangSwitcherWrap>
        {localizations.map((locale) => (
          <S.Href key={locale.locale} href={locale.slug} locale={locale.locale}>
            {locale.name}
          </S.Href>
        ))}
      </S.LangSwitcherWrap>
      {copyrightParsed && (
        <S.CopyrightWrap>
          <div dangerouslySetInnerHTML={{ __html: copyrightParsed }} />
        </S.CopyrightWrap>
      )}
    </S.Wrap>
  )
}
