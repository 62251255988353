import { FC } from 'react'
import * as S from './FooterLinksColumn.styled'

export type FooterLinksColumnProps = {
  telNumber?: string
  email?: string
  linkedinUrl?: string
  facebookUrl?: string
  instagramUrl?: string
}

export const FooterLinksColumn: FC<FooterLinksColumnProps> = ({
  telNumber,
  email,
  linkedinUrl,
  instagramUrl,
  facebookUrl,
}) => {
  return (
    <S.Wrap>
      <S.LogoWrap>
        <S.Logo />
      </S.LogoWrap>
      <S.ContactWithIcons telNumber={telNumber} email={email} />
      <S.SocialIcons instagramUrl={instagramUrl} linkedInUrl={linkedinUrl} facebookUlr={facebookUrl} />
    </S.Wrap>
  )
}
