import { FC } from 'react'
import { FooterProps } from '@organisms/Footer'
import * as S from './FooterDesktop.styled'
import { FooterLinksColumn, FooterLinksColumnProps } from '@molecules/FooterLinksColumn'
import { ContactProps } from '@molecules/FooterContact'

export const FooterDesktop: FC<FooterProps> = ({ className, footerNav2, footerNav1, globalEntity }) => {
  const footerLinks: FooterLinksColumnProps = {
    telNumber: globalEntity?.contact?.telNumber ?? undefined,
    email: globalEntity?.contact?.mail ?? undefined,
    linkedinUrl: globalEntity?.contact?.linkedInUrl ?? undefined,
    instagramUrl: globalEntity?.contact?.instagramUrl ?? undefined,
    facebookUrl: globalEntity?.contact?.facebookUrl ?? undefined,
  }
  const contact: ContactProps = {
    address: globalEntity?.contact?.address ?? undefined,
    ic: globalEntity?.contact?.ic ?? undefined,
    dic: globalEntity?.contact?.dic ?? undefined,
  }
  return (
    <S.VisibilityWrap className={className}>
      <S.Wrap>
        <FooterLinksColumn {...footerLinks} />
        {footerNav1 && (
          <S.FooterColumn nav={footerNav1} title={globalEntity?.footer?.navTitle1 ?? ''} isMobile={false} />
        )}
        {footerNav2 && (
          <S.FooterColumn nav={footerNav2} title={globalEntity?.footer?.navTitle2 ?? ''} isMobile={false} />
        )}
        <S.FooterColumn contact={contact} title={globalEntity?.footer?.addressTitle ?? ''} isMobile={false} />
      </S.Wrap>
    </S.VisibilityWrap>
  )
}
