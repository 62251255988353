import { FC } from 'react'
import * as S from './FooterContact.styled'

export type ContactProps = {
  address?: string
  ic?: string
  dic?: string
}

export const FooterContact: FC<ContactProps> = ({ address, ic, dic }) => {
  return (
    <S.Grid>
      {address && (
        <>
          <S.IconWrap>
            <S.Icon />
          </S.IconWrap>
          <S.TextWrap>
            <div dangerouslySetInnerHTML={{ __html: address }} />
          </S.TextWrap>
        </>
      )}
      {ic && (
        <>
          <S.IconWrap>IČ:</S.IconWrap>
          <S.TextWrap>{ic}</S.TextWrap>
        </>
      )}
      {dic && (
        <>
          <S.IconWrap>DIČ:</S.IconWrap>
          <S.TextWrap>{dic}</S.TextWrap>
        </>
      )}
    </S.Grid>
  )
}
