import styled from '@emotion/styled'
import Phone from '@atoms/Icons/Phone.svg'
import Mail from '@atoms/Icons/Mail.svg'
import Link from 'next/link'

export const Wrap = styled.div``

export const PhoneIcon = styled(Phone)`
  color: ${({ theme }) => theme.colors.secondary};
  height: 20px;
  margin-right: 24px;
  transition: color 0.3s ease;
`

export const EmailIcon = styled(Mail)`
  color: ${({ theme }) => theme.colors.secondary};
  height: 20px;
  margin-right: 24px;
  transition: color 0.3s ease;
`
export const Href = styled(Link)`
  display: flex;
  align-items: center;
  text-decoration: none;
  color: ${({ theme }) => theme.colors.primary};
  transition: color 0.3s ease;
  margin-bottom: 8px;

  &:hover {
    color: ${({ theme }) => theme.colors.secondary};

    ${PhoneIcon} {
      color: ${({ theme }) => theme.colors.primary};
    }

    ${EmailIcon} {
      color: ${({ theme }) => theme.colors.primary};
    }
  }
`
