import { FC } from 'react'
import { FooterProps } from '@organisms/Footer'
import * as S from './FooterMobile.styled'
import { FooterColumn } from '@molecules/FooterColumn/FooterColumn'
import { ContactProps } from '@molecules/FooterContact/FooterContact'

export const FooterMobile: FC<FooterProps> = ({ footerNav1, footerNav2, globalEntity, className }) => {
  const contact: ContactProps = {
    address: globalEntity?.contact?.address ?? undefined,
    ic: globalEntity?.contact?.ic ?? undefined,
    dic: globalEntity?.contact?.dic ?? undefined,
  }
  return (
    <S.Wrap className={className}>
      <S.LogoContainer>
        <S.LogoWrap>
          <S.Logo />
        </S.LogoWrap>
      </S.LogoContainer>
      <S.IconsWrap>
        <S.SocialIcons
          facebookUlr={globalEntity?.contact?.facebookUrl}
          instagramUrl={globalEntity?.contact?.instagramUrl}
          linkedInUrl={globalEntity?.contact?.linkedInUrl}
        />
        <S.fastActions>
          {globalEntity?.contact?.mail && (
            <S.MailLink href={`mailto:${globalEntity?.contact?.mail}`} title={globalEntity?.contact?.mail}>
              <S.MailIcon />
            </S.MailLink>
          )}
          {globalEntity?.contact?.telNumber && (
            <S.PhoneLink
              href={`tel:${globalEntity.contact.telNumber.replaceAll(' ', '')}`}
              title={globalEntity?.contact?.telNumber}
            >
              <S.PhoneIcon />
            </S.PhoneLink>
          )}
        </S.fastActions>
      </S.IconsWrap>
      <S.CollapsesWrap>
        <FooterColumn title={globalEntity?.footer?.navTitle1 ?? ''} nav={footerNav1} isMobile={true} />
        <FooterColumn title={globalEntity?.footer?.navTitle2 ?? ''} nav={footerNav2} isMobile={true} />
        <FooterColumn title={globalEntity?.footer?.addressTitle ?? ''} contact={contact} isMobile={true} />
      </S.CollapsesWrap>
    </S.Wrap>
  )
}
