import styled from '@emotion/styled'
import Facebook from '@atoms/Icons/Facebook.svg'
import Instagram from '@atoms/Icons/Instagram.svg'
import LinkedIn from '@atoms/Icons/LinkedIn.svg'
import Link from 'next/link'

export const Wrap = styled.div``

export const Href = styled(Link)`
  color: ${({ theme }) => theme.colors.primary};
  padding: 8px 12px;
  transition: color 0.3s ease;

  &:hover {
    color: ${({ theme }) => theme.colors.secondary};
  }

  &:first-of-type {
    padding-left: 0;
  }

  &:last-of-type {
    padding-right: 0;
  }

  svg {
    height: 26px;
    width: 26px;
  }
`

export const FacebookIcon = styled(Facebook)``

export const InstagramIcon = styled(Instagram)``

export const LinkedInIcon = styled(LinkedIn)``
