import styled from '@emotion/styled'
import LogoSVG from '@atoms/LogoSVG/LogoSVG.svg'
import Mail from '@atoms/Icons/Mail.svg'
import Phone from '@atoms/Icons/Phone.svg'
import { SocialIcons as SocialIconsComponent } from '@molecules/SocialIcons/SocialIcons'
import { CollapseButton } from '@molecules/FooterCollapse/FooterCollapse.styled'
import Link from 'next/link'

export const Wrap = styled.div`
  padding-top: 10px;
`

export const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const LogoWrap = styled.div`
  width: 200px;
  margin-bottom: 48px;
`

export const Logo = styled(LogoSVG)``

export const MailLink = styled(Link)`
  color: ${({ theme }) => theme.colors.primary};
  margin-right: 24px;
`

export const PhoneLink = styled(Link)`
  color: ${({ theme }) => theme.colors.primary};
`

export const fastActions = styled.div`
  display: flex;
`

export const MailIcon = styled(Mail)`
  height: 26px;
`

export const PhoneIcon = styled(Phone)`
  height: 24px;
`

export const IconsWrap = styled.div`
  display: flex;
  justify-content: space-between;
`

export const SocialIcons = styled(SocialIconsComponent)``

export const CollapsesWrap = styled.div`
  padding: 4px 0;
  margin-top: 40px;
  border-radius: 10px;
  margin-bottom: 32px;
  display: flex;
  width: 100%;
  flex-wrap: wrap;

  background-color: ${({ theme }) => theme.colors.grayLighter};
  box-shadow: -10px -10px 20px #fff, -30px -30px 30px ${({ theme }) => theme.colors.grayLighter},
    10px 0px 30px rgba(0, 0, 0, 0.2), 10px -10px 30px ${({ theme }) => theme.colors.grayLighter};

  ${CollapseButton} {
    &:not(:first-of-type) {
      border-top: 1px solid #fff;
    }

    &:not(:last-of-type) {
      border-bottom: 1px solid ${({ theme }) => theme.colors.grayDarker};
    }
  }
`
