import { FC } from 'react'
import * as S from './NavOpenButton.styled'

export type NavOpenButtonProps = {
  className?: string
  onClick: () => void
}

export const NavOpenButton: FC<NavOpenButtonProps> = ({ className, onClick }) => {
  return (
    <S.Button className={className} onClick={onClick}>
      <S.Icon />
    </S.Button>
  )
}
