import styled from '@emotion/styled'
import Pin from '@atoms/Icons/Pin.svg'

export const Grid = styled.div`
  padding-top: 8px;
  display: grid;
  grid-template-columns: 30px auto;
  grid-template-rows: auto;
  gap: 8px;
`

export const IconWrap = styled.div``;

export const TextWrap = styled.div``;

export const Icon = styled(Pin)`
  color: ${({ theme }) => theme.colors.secondary};
  margin-top: 4px;
`;
