import styled from '@emotion/styled'
import { NavItem } from '@atoms/NavItem'
import { Href } from '@atoms/NavLink/NavLink.styled'

export const Nav = styled.nav``

export const List = styled.ul``

export const Item = styled(NavItem)`
  padding-top: 8px;
  padding-bottom: 0;

  ${Href} {
    font-weight: 400;
    transition: color 0.3s ease;

    &:hover {
      color: ${({ theme }) => theme.colors.secondary};
    }
  }
`
