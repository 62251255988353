import styled from '@emotion/styled'
import { mediaQuery } from '../../../themes/utils/breakpoints'

export const List = styled.ul`
  display: flex;
  list-style: none;
  align-items: center;
  gap: 40px;

  ${mediaQuery.desktop.up} {
    gap: 56px;
  }
`

export const LangSwitcher = styled.div`
  position: relative;
  z-index: 2;
`

export const LangSwitcherButton = styled.button<{ isOpen: boolean }>`
  color: ${({ theme }) => theme.colors.primary};
  border-radius: 6px;
  border: none;
  padding: 6px 12px;
  cursor: pointer;
  outline: inherit;
  z-index: 1;
  position: relative;
  font-weight: 600;
  font-size: 14px;
  text-transform: uppercase;
  background-color: transparent;

  span {
    position: relative;
    display: inline-block;
    rotate: ${({ isOpen }) => (isOpen ? '180deg' : '0')};
    top: ${({ isOpen }) => (isOpen ? '2px' : '0')};
  }
`

export const LangSwitcherList = styled.div<{ isOpen: boolean }>`
  display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
  position: absolute;
  right: 0;
  top: 0;
  flex-direction: column;
  padding-right: 18px;
  padding-left: 12px;
  padding-top: 4px;
  padding-bottom: 4px;
  border-radius: 6px;
  padding-top: 28px;
  background-color: ${({ theme }) => theme.colors.grayLighter};
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.1);
  width: 100%;
  text-transform: uppercase;

  a {
    color: ${({ theme }) => theme.colors.primary};
    font-weight: 600;
    text-decoration: none;
  }
`

export const Divider = styled.span`
  display: block;
  height: 58px;
  background-color: ${({ theme }) => theme.colors.primary};
  width: 1px;
`
