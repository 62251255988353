import { FC } from 'react'
import * as S from './NavMobile.styled'
import { INavigationItemEntity } from '@strapi/navigation'
import { NavItemMobile } from '@atoms/NavItemMobile'
import Link from 'next/link'
import { ParsedLocalizations } from '@strapi/localizations'

export type NavMobileProps = {
  navigationEntity: INavigationItemEntity[]
  className?: string
  isOpen: boolean
  onClick: () => void
  currentLocale: 'en' | 'cs' | 'de'
  localizations: ParsedLocalizations
}

export const NavMobile: FC<NavMobileProps> = ({ navigationEntity, className, isOpen, onClick, localizations }) => {
  return (
    <S.Wrap isOpen={isOpen}>
      <S.NavCloseButton onClick={onClick} />
      <S.List className={className}>
        {navigationEntity.length > 0 && navigationEntity?.map((item) => <NavItemMobile item={item} key={item.id} />)}
      </S.List>
      <S.Divider />
      <S.CSVersionItem>
        {localizations.map((locale) => (
          <Link key={locale.locale} href={locale.slug} locale={locale.locale}>
            {locale.locale}
          </Link>
        ))}
      </S.CSVersionItem>
    </S.Wrap>
  )
}
