import { Button, ButtonType, LinkButton } from '@atoms/Button'
import * as S from './ButtonIcon.styled'
import { IComponentComponentsButton } from '@generated/index'
import { FC, ReactNode } from 'react'

type Props = ButtonType & {
  children?: ReactNode
  href?: string
  isExternal?: boolean
  button?: IComponentComponentsButton
  onClick?: () => void
  className?: string
  type?: 'button' | 'submit' | 'reset'
  disabled?: boolean
  sendButton?: {
    loading: boolean
  }
}

export const ButtonIcon: FC<Props> = ({
  href,
  variant,
  moduleBackground,
  children,
  button,
  onClick,
  type,
  className,
  disabled,
  sendButton,
}) => {
  if (button?.url) {
    href = button.url
  }
  if (button?.page?.slug) {
    href = button.page.slug
  }

  if (href) {
    return (
      <LinkButton variant={variant} moduleBackground={moduleBackground} className={className} href={href}>
        <S.Text>{children}</S.Text>
        <S.ArrowIcon />
      </LinkButton>
    )
  }
  return (
    <Button
      type={type}
      variant={variant}
      moduleBackground={moduleBackground}
      className={className}
      onClick={() => onClick}
      disabled={disabled}
    >
      <S.Text>{children}</S.Text>
      {sendButton?.loading ? <S.Loader /> : <S.ArrowIcon />}
    </Button>
  )
}
