import { NavItem } from '@atoms/NavItem'
import * as S from './Nav.styled'
import { INavigationItemEntity } from '@strapi/navigation'
import Link from 'next/link'
import { useState } from 'react'
import { ParsedLocalizations } from '@strapi/localizations'

type NavProps = {
  navigationEntity: INavigationItemEntity[]
  className?: string
  currentLocale: 'en' | 'cs' | 'de'
  localizations: ParsedLocalizations
}

export const Nav: React.FC<NavProps> = ({ navigationEntity, className, currentLocale, localizations }) => {
  const [isOpen, setIsOpen] = useState(false)
  return (
    <S.List className={className}>
      {navigationEntity.length > 0 && navigationEntity?.map((item) => <NavItem item={item} key={item.id} />)}
      <S.Divider />
      <S.LangSwitcher>
        <S.LangSwitcherButton isOpen={isOpen} onClick={() => setIsOpen(!isOpen)}>
          {currentLocale} <span aria-hidden>▾</span>
        </S.LangSwitcherButton>
        <S.LangSwitcherList isOpen={isOpen}>
          {localizations?.map((locale) => (
            <Link key={locale.locale} href={locale.slug} locale={locale.locale} onClick={() => setIsOpen(false)}>
              {locale.locale}
            </Link>
          ))}
        </S.LangSwitcherList>
      </S.LangSwitcher>
    </S.List>
  )
}
