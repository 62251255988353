import { FC, ReactNode, useState } from 'react'
import { Disclosure } from '@reach/disclosure'
import * as S from './FooterCollapse.styled'

type FooterCollapseProps = {
  title?: string
  children?: ReactNode
}

export const FooterCollapse: FC<FooterCollapseProps> = ({ title, children }) => {
  const [isOpen, setIsOpen] = useState(false)
  return (
    <>
      <Disclosure open={isOpen} onChange={() => setIsOpen(!isOpen)}>
        <S.CollapseButton>
          <S.Title>{title}</S.Title>
          <S.ArrowWrap isOpen={isOpen}>
            <S.Arrow />
          </S.ArrowWrap>
        </S.CollapseButton>
        <S.CollapsePanel>{children}</S.CollapsePanel>
      </Disclosure>
    </>
  )
}
