import { FC } from 'react'
import * as S from './NavLink.styled'

export type NavLinkProps = {
  url: string
  title: string
}

export const NavLink: FC<NavLinkProps> = ({ url, title }) => {
  return <S.Href href={url}>{title}</S.Href>
}
