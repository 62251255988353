import { FC } from 'react'
import * as S from './Footer.styled'
import Image from 'next/image'
import { INavigationItemEntity } from '@strapi/navigation'
import { IGlobalEntity } from '@generated/index'
import { FooterBottom } from '@molecules/FooterBottom'
import { ParsedLocalizations } from '@strapi/localizations'

export type FooterProps = {
  footerNav1: INavigationItemEntity[]
  footerNav2: INavigationItemEntity[]
  globalEntity: IGlobalEntity
  className?: string
}

export const Footer: FC<FooterProps & { localizations: ParsedLocalizations }> = ({
  footerNav1,
  footerNav2,
  globalEntity,
  className,
  localizations,
}) => {
  return (
    <S.OutsideContainer className={className}>
      <S.BackgroundWrap>
        <Image src={'/bg.png'} width={1920} height={269} alt="background" quality={100} />
      </S.BackgroundWrap>
      <S.TextContainer>
        <S.Container>
          <S.FooterMobile footerNav1={footerNav1} footerNav2={footerNav2} globalEntity={globalEntity} />
          <S.FooterDesktop footerNav1={footerNav1} footerNav2={footerNav2} globalEntity={globalEntity} />
        </S.Container>
        <S.Divider />
        <S.Container>
          <FooterBottom copyrightText={globalEntity?.footer?.copyrightText} localizations={localizations} />
        </S.Container>
      </S.TextContainer>
    </S.OutsideContainer>
  )
}
