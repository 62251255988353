import styled from '@emotion/styled'
import LogoSVGSmall from '../../atoms/LogoSVG/LogoSVGSmall.svg'
import LogoSVG from '../../atoms/LogoSVG/LogoSVG.svg'
import { mediaQuery } from '../../../themes/utils/breakpoints'
import Link from 'next/link'

export const Logo = styled(LogoSVG)`
  width: 100%;
  height: 100%;
  display: none;

  ${mediaQuery.tablet.up} {
    display: block;
  }
`

export const LogoSmall = styled(LogoSVGSmall)`
  width: 100%;
  height: 100%;
  display: block;

  ${mediaQuery.tablet.up} {
    display: none;
  }
`

export const Wrapper = styled(Link)`
  display: block;
  aspect-ratio: 46 / 30;
  width: 66px;

  ${mediaQuery.tablet.up} {
    width: 187px;
    height: 30px;
  }
`
