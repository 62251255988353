import styled from '@emotion/styled'
import LogoSVG from '@atoms/LogoSVG/LogoSVG.svg'
import { SocialIcons as SocialIconsComponent } from '@molecules/SocialIcons/SocialIcons'
import { ContactWithIcons as ContactWithIconsComponent } from '@molecules/ContactWithIcons/ContactWithIcons'

export const Wrap = styled.div``

export const LogoWrap = styled.div`
  width: 200px;
  margin-bottom: 48px;
`

export const Logo = styled(LogoSVG)``

export const SocialIcons = styled(SocialIconsComponent)``

export const ContactWithIcons = styled(ContactWithIconsComponent)`
  margin-bottom: 60px;
`
