import { FC } from 'react'
import * as S from './NavItem.styled'
import { INavigationItemEntity } from '@strapi/navigation'
import { NavLink } from '../NavLink'

export type NavItemProps = {
  item: INavigationItemEntity
  className?: string
}

export const NavItem: FC<NavItemProps> = ({ item, className }) => {
  let url = "/";
  if (item.path) {
    url = `/${item.path}`;
  }
  if (item.related?.slug) {
    url = `/${item.related.slug}`;
  }
  return (
    <S.ListItem className={className}>
      <NavLink url={url} title={item.title} />
    </S.ListItem>
  );
};
