import styled from '@emotion/styled'
import Link from 'next/link'
import { mediaQuery } from '../../../themes/utils/breakpoints'

export const Wrap = styled.div`
  display: flex;
  padding-top: 24px;
  padding-bottom: 24px;
  flex-direction: column;

  ${mediaQuery.tablet.up} {
    justify-content: space-between;
    flex-direction: row;
  }
`

export const CopyrightWrap = styled.div`
  text-align: center;
`

export const LangSwitcherWrap = styled.div`
  margin: 0 auto 8px;
`

export const Href = styled(Link)`
  color: ${({ theme }) => theme.colors.primary};
  text-decoration: none;
  transition: color 0.3s ease;

  &:nth-of-type(1) {
    margin-right: 46px;
  }

  &:hover {
    color: ${({ theme }) => theme.colors.secondary};
  }

  ${mediaQuery.tablet.up} {
    margin-right: 62px;
  }
`
