import styled from '@emotion/styled'
// @ts-ignore
import Bars from '../Icons/Bars.svg'

export const Button = styled.button`
  border: 0;
  outline: 0;
  padding: 10px;
  background: transparent;
`

export const Icon = styled(Bars)`
  width: 36px;
  height: 36px;
`
