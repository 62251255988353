import { FC } from 'react'
import * as S from './NavItemMobile.styled'
import { NavLink } from '../NavLink'
import { INavigationItemEntity } from '@strapi/navigation'

export type NavItemMobileProps = {
  item: INavigationItemEntity
}

export const NavItemMobile: FC<NavItemMobileProps> = ({ item }) => {
  let url = '/'
  if (item.path) {
    url = `/${item.path}`
  }
  if (item.related?.slug) {
    url = `/${item.related.slug}`
  }
  return (
    <S.ListItem>
      <NavLink url={url} title={item.title} />
    </S.ListItem>
  )
}
