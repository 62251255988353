import styled from '@emotion/styled'
import { Container as ContainerComponent } from '@atoms/Container'
import { FooterMobile as FooterMobileComponent } from '@molecules/FooterMobile/FooterMobile'
import { FooterDesktop as FooterDesktopComponent } from '@molecules/FooterDesktop/FooterDesktop'
import { mediaQuery } from '../../../themes/utils/breakpoints'

export const OutsideContainer = styled.div`
  position: relative;
`

export const Container = styled(ContainerComponent)``

export const Divider = styled.div`
  width: 100%;
  height: 2px;
  background-color: #fff;
  border-top: 1px solid #d9d9d9;
`

export const TextContainer = styled.div`
  background-color: #e8e8e8;
`

export const BackgroundWrap = styled.div`
  width: 100%;
  line-height: 0;
`

export const FooterMobile = styled(FooterMobileComponent)`
  display: block;
  padding-bottom: 12px;

  ${mediaQuery.tablet.up} {
    display: none;
    padding-bottom: 64px;
  }
`

export const FooterDesktop = styled(FooterDesktopComponent)`
  display: none;

  ${mediaQuery.tablet.up} {
    display: block;
    padding-bottom: 64px;
  }
`
