import { FC } from 'react'
import * as S from './NavCloseButton.styled'

export type NavCloseButtonProps = {
  className?: string
  onClick: () => void
}

export const NavCloseButton: FC<NavCloseButtonProps> = ({ className, onClick }) => {
  return (
    <S.Button className={className} onClick={onClick}>
      <S.Icon />
    </S.Button>
  )
}
