import { Logo } from '@molecules/Logo/Logo'
import * as S from './Header.styled'
import { INavigationItemEntity } from '@strapi/navigation'
import { Container } from '@atoms/Container'
import { useState } from 'react'
import { ParsedLocalizations } from '@strapi/localizations'

type HeaderProps = {
  navigationEntity: INavigationItemEntity[]
  currentLocale: 'en' | 'cs' | 'de'
  localizations: ParsedLocalizations
}

export const Header: React.FC<HeaderProps> = ({ navigationEntity, currentLocale, localizations }) => {
  const [isOpen, setIsOpen] = useState(false)
  const handleOpen = () => {
    setIsOpen(true)
  }
  const handleClose = () => {
    setIsOpen(false)
  }
  return (
    <Container>
      <S.Wrapper>
        <Logo />
        <S.Nav navigationEntity={navigationEntity} currentLocale={currentLocale} localizations={localizations} />
        <S.OpenButton onClick={handleOpen} />
      </S.Wrapper>
      <S.NavMobile
        navigationEntity={navigationEntity}
        isOpen={isOpen}
        onClick={handleClose}
        currentLocale={currentLocale}
        localizations={localizations}
      />
    </Container>
  )
}
