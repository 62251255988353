import styled from '@emotion/styled'
import { FooterColumn as FooterColumnComponent } from '@molecules/FooterColumn'

export const VisibilityWrap = styled.div`
  display: block;
`

export const Wrap = styled.div`
  display: flex;
  padding-top: 20px;
  justify-content: space-between;
`

export const FooterColumn = styled(FooterColumnComponent)``
