import { FC } from 'react'
import { INavigationItemEntity } from '@strapi/navigation'
import * as S from './FooterNav.styled'

export type FooterNavProps = {
  nav: INavigationItemEntity[]
}

export const FooterNav: FC<FooterNavProps> = ({ nav }) => {
  return (
    <S.Nav>
      <S.List>{nav.length > 0 && nav.map((item) => <S.Item key={item.id} item={item} />)}</S.List>
    </S.Nav>
  )
}
