import styled from '@emotion/styled'
import { NavCloseButton as NavCloseButtonComponent } from '../../atoms/NavCloseButton'

export const Wrap = styled.div<{ isOpen: boolean }>`
  display: flex;
  position: fixed;
  background-color: ${({ theme }) => theme.colors.grayDarker};
  width: 320px;
  height: 100vh;
  z-index: 100;
  top: 0;
  right: 0;
  flex-direction: column;
  padding-left: 32px;
  padding-right: 32px;
  transform: translateX(100%);
  transition: transform 0.3s ease-in-out;

  ${({ isOpen }) =>
    isOpen &&
    `
    transform: translateX(0);
    box-shadow: -7px 5px 20px rgba(0, 0, 0, 0.3), inset 7px 12px 15px #fff;
  `}
`

export const NavCloseButton = styled(NavCloseButtonComponent)`
  position: absolute;
  top: 16px;
  right: 16px;
`

export const List = styled.ul`
  display: flex;
  flex-direction: column;
  list-style: none;
  gap: 24px;
  width: 100%;
  margin-top: 80px;
`

export const CSVersionItem = styled.div`
  margin-top: 8px;

  a {
    color: ${({ theme }) => theme.colors.primary};
    font-weight: 600;
    font-size: 14px;
    text-transform: uppercase;
    margin-right: 12px;
    text-decoration: none;
  }
`

export const Divider = styled.span`
  margin-top: 32px;
  display: block;
  height: 1px;
  background-color: ${({ theme }) => theme.colors.primary};
  width: 100%;
`
