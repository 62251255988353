import { FC } from 'react'
import * as S from './SocialIcons.styled'

export type SocialIconsProps = {
  facebookUlr?: string
  instagramUrl?: string
  linkedInUrl?: string
  className?: string
}

export const SocialIcons: FC<SocialIconsProps> = ({ facebookUlr, linkedInUrl, instagramUrl, className }) => {
  return (
    <S.Wrap className={className}>
      {linkedInUrl && (
        <S.Href href={linkedInUrl}>
          <S.LinkedInIcon />
        </S.Href>
      )}
      {instagramUrl && (
        <S.Href href={instagramUrl}>
          <S.InstagramIcon />
        </S.Href>
      )}
      {facebookUlr && (
        <S.Href href={facebookUlr}>
          <S.FacebookIcon />
        </S.Href>
      )}
    </S.Wrap>
  )
}
