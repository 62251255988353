import styled from '@emotion/styled'
import { mediaQuery } from '../../../themes/utils/breakpoints'
import { Nav as NavComponent } from '../../molecules/Nav'
import { NavMobile as NavMobileComponent } from '../../molecules/NavMobile'
import { NavOpenButton as NavOpenButtonComponent } from '../../atoms/NavOpenButton'

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 12px auto 0;

  ${mediaQuery.tabletXs.up} {
    margin: 12px auto 61px;
  }

  ${mediaQuery.desktop.up} {
    margin: 48px auto 81px;
  }
`

export const OpenButton = styled(NavOpenButtonComponent)`
  ${mediaQuery.tablet.up} {
    display: none;
  }
`

export const Nav = styled(NavComponent)`
  display: none;

  ${mediaQuery.tablet.up} {
    display: flex;
  }
`

export const NavMobile = styled(NavMobileComponent)`
  ${mediaQuery.tablet.up} {
    display: none;
  }
`
