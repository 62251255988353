import { DisclosureButton, DisclosurePanel } from '@reach/disclosure'
import styled from '@emotion/styled'
import { H3 } from '@atoms/Title'
import ArrowWebthinx from '@atoms/Icons/ArrowWebthinx.svg'

export const CollapseButton = styled(DisclosureButton)`
  all: unset;
  width: 100%;
  max-width: 100%;
  padding: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const ArrowWrap = styled.div<{ isOpen: boolean }>`
  transform: rotate(${({ isOpen }) => (isOpen ? '180deg' : '0deg')});
  transition: transform 0.2s ease-in-out;
  line-height: 0;
`

export const Arrow = styled(ArrowWebthinx)<{ isOpen: boolean }>`
  color: ${({ theme }) => theme.colors.primary};
  width: 18px;
`

export const Title = styled(H3)``

export const CollapsePanel = styled(DisclosurePanel)`
  padding: 6px 12px 12px;
  width: 100%;
  box-shadow: inset -3px -3px 5px rgba(255, 255, 255, 0.8), inset 3px 3px 5px rgba(0, 0, 0, 0.1);
`
