import styled from '@emotion/styled'
import { H3 } from '@atoms/Title'

export const Wrap = styled.div`
  display: flex;
  flex-direction: column;
`

export const Title = styled(H3)`
  margin-bottom: 48px;
`
