import { FC } from 'react'
import * as S from './ContactWithIcons.styled'

type ContactWithIconsProps = {
  telNumber?: string
  email?: string
  className?: string
}

export const ContactWithIcons: FC<ContactWithIconsProps> = ({ telNumber, email, className }) => {
  return (
    <S.Wrap className={className}>
      {telNumber && (
        <S.Href href={`tel:${telNumber.replaceAll(' ', '')}`}>
          <S.PhoneIcon />
          {telNumber}
        </S.Href>
      )}
      {email && (
        <S.Href href={`mailto:${email}`}>
          <S.EmailIcon />
          {email}
        </S.Href>
      )}
    </S.Wrap>
  )
}
