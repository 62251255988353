import { FC } from 'react'
import { INavigationItemEntity } from '@strapi/navigation'
import { FooterCollapse } from '@molecules/FooterCollapse/FooterCollapse'
import { FooterNav } from '@molecules/FooterNav/FooterNav'
import { ContactProps, FooterContact } from '@molecules/FooterContact/FooterContact'
import * as S from './FooterColumn.styled'

export type FooterColumnProps = {
  title: string
  nav?: INavigationItemEntity[]
  isMobile?: boolean
  contact?: ContactProps
}

export const FooterColumn: FC<FooterColumnProps> = ({ isMobile, title, contact, nav }) => {
  if (isMobile) {
    return (
      <FooterCollapse title={title}>
        {nav && <FooterNav nav={nav} />}
        {contact && <FooterContact {...contact} />}
      </FooterCollapse>
    )
  } else {
    return (
      <S.Wrap>
        <S.Title>{title}</S.Title>
        {nav && <FooterNav nav={nav} />}
        {contact && <FooterContact {...contact} />}
      </S.Wrap>
    )
  }
}
