import { FC } from 'react'
import * as S from './Container.styled'

export type ContainerProps = {
  children: React.ReactNode
  className?: string
  variant?: 'white' | 'transparent'
  id?: string
}

export const Container: FC<ContainerProps> = ({ children, className, variant = 'transparent', id }) => {
  return (
    <S.ContainerWrap variant={variant} id={id}>
      <S.Container className={className}>{children}</S.Container>
    </S.ContainerWrap>
  )
}
