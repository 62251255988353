import styled from '@emotion/styled'

export const ContainerWrap = styled.div<{ variant?: 'white' | 'transparent' }>`
  background-color: ${(props) => (props.variant === 'white' ? '#F4F4F4' : 'transparent')};
`

export const Container = styled.div`
  display: block;
  max-width: ${({ theme }) => theme.container.maxWidth};
  padding: 0 ${({ theme }) => theme.container.padding};
  margin: 0 auto;
  position: relative;
`
