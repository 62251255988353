export type DesignBreakpoints = 'tablet' | 'tabletXs' | 'desktop'

const breakpoints: Record<DesignBreakpoints, number> = {
  tabletXs: 768,
  tablet: 990,
  desktop: 1300,
}

export const sizes = {
  tabletXs: `(max-width: ${breakpoints.tabletXs}px)`,
  tablet: `(max-width: ${breakpoints.tablet}px)`,
  desktop: `(max-width: ${breakpoints.desktop}px)`,
}

export const mediaQuery: Record<DesignBreakpoints, { up: string; only: string }> = {
  tabletXs: {
    only: `@media (min-width: ${breakpoints.tabletXs}px) and (max-width: ${breakpoints.tablet - 1}px)`,
    up: `@media (min-width: ${breakpoints.tabletXs}px)`,
  },
  tablet: {
    only: `@media (min-width: ${breakpoints.tablet}px) and (max-width: ${breakpoints.desktop - 1}px)`,
    up: `@media (min-width: ${breakpoints.tablet}px)`,
  },
  desktop: {
    only: `@media (min-width: ${breakpoints.desktop}px)`,
    up: `@media (min-width: ${breakpoints.desktop}px)`,
  },
}

export default breakpoints
