import styled from '@emotion/styled'
import { mediaQuery } from '../../../themes/utils/breakpoints'

export const H1Big = styled.h1`
  font-size: 3.5rem;
  font-family: ${(props) => props.theme.fonts.headings};
  font-weight: 700;
  line-height: 1.2;
  color: ${(props) => props.theme.colors.secondary};

  ${mediaQuery.tabletXs.up} {
    font-size: 5rem;
  }

  ${mediaQuery.desktop.up} {
    font-size: 7.3rem;
  }
`

export const H1 = styled.h1`
  font-family: ${(props) => props.theme.fonts.headings};
  line-height: 1;
  font-size: 6rem;
  font-weight: 700;
  color: ${(props) => props.theme.colors.secondary};
`

export const H2 = styled.h2`
  font-family: ${(props) => props.theme.fonts.body};
  font-size: 2rem;
  font-weight: 400;
  line-height: 1;
  color: ${(props) => props.theme.colors.secondary};
`

export const H3 = styled.h3`
  font-family: ${(props) => props.theme.fonts.body};
  font-size: 1.0625rem;
  line-height: 1.4;
  color: ${(props) => props.theme.colors.secondary};
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 0.3px;
`

export const H4 = styled.h4`
  font-family: ${(props) => props.theme.fonts.body};
  font-size: 1rem;
  line-height: 1;
  color: ${(props) => props.theme.colors.secondary};
`
