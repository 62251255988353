import styled from '@emotion/styled'
import { mediaQuery } from '../../../themes/utils/breakpoints'
import Link from 'next/link'
import isPropValid from '@emotion/is-prop-valid'

export type ButtonType = {
  variant: 'primary' | 'secondary' | 'hero'
  moduleBackground: 'grayLighter' | 'grayDarker' | 'primary'
}

export const Button = styled('button', { shouldForwardProp: isPropValid })<ButtonType>`
  font-family: inherit;
  margin: 0;
  font-size: 0.875rem;
  padding: 17px 38px;
  border-radius: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 5px solid;
  line-height: 1;
  position: relative;
  transition: all 0.5s;
  text-decoration: none;
  width: 300px;

  ${mediaQuery.tablet.up} {
    width: 320px;
  }

  &::after {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    border-radius: 100px;
    background: transparent;
    opacity: 0;
    transition: opacity 0.3s;
  }

  &:hover {
    cursor: pointer;

    svg {
      margin-right: -5px;
    }
  }
  ${(props) => {
    if (props.variant === 'hero') {
      return `
      color: #fff;
        background-color: ${props.theme.colors.primary};
        border-color: ${props.theme.colors.primary};
        box-shadow: -4px -4px 5px rgba(255, 255, 255, 0.7), -6px -6px 30px rgba(0, 0, 0, 0.15), 4px 4px 5px rgba(0, 0, 0, 0.10), 10px 10px 20px rgba(255, 255, 255, 1);
        
        &::after {
          box-shadow: inset 3px 3px 5px rgba(0, 0, 0, 0.1), inset -3px -3px 5px #FFFFFF;
        }
        
        &:hover {
           box-shadow: ${
             props.moduleBackground === 'grayLighter'
               ? '-10px -10px 20px #FFFFFF, 10px 10px 20px #DCDCDC'
               : '-10px -10px 20px rgba(255, 255, 255, 0.8), 10px 10px 20px #DCDCDC;'
           };
          border: 5px solid ${props.theme.colors.grayLighter};
          background-color: ${props.theme.colors.grayLighter};
          color: ${props.theme.colors.primary};
          
          &::after {
            opacity: 1;
          }
        }
       ${mediaQuery.tabletXs.up} {
        background-color: ${props.theme.colors.grayLighter};
        color: ${props.theme.colors.primary};
        border-color: transparent;
        box-shadow: -10px -10px 20px #FF9A92, 10px 10px 20px #DC6156;
        
        &::after {
          box-shadow: inset 3px 3px 5px rgba(0, 0, 0, 0.1), inset -3px -3px 5px #FFFFFF;
        }
        
        &:hover {
          background-color: ${props.theme.colors.grayDarker};
          box-shadow: -10px -10px 20px #FF9A92, 10px 10px 20px #DC6156;
          
          &::after {
            opacity: 1;
          }
        }
       }
      `
    } else if (props.variant === 'primary') {
      return `
        color: #fff;
        background-color: ${props.theme.colors.primary};
        border-color: ${props.theme.colors.primary};
        box-shadow: -10px -10px 20px #FFFFFF, 10px 10px 20px rgba(255, 117, 105, 0.35);
        
        &[disabled] {
          cursor: not-allowed;
          
          &:hover {
            color: #fff;
            background-color: ${props.theme.colors.primary};
            border-color: ${props.theme.colors.primary};
            box-shadow: -10px -10px 20px #FFFFFF, 10px 10px 20px rgba(255, 117, 105, 0.35);
            
            &::after {
              opacity: 0;
            }
          }
        }
        
        &::after {
          box-shadow: inset 3px 3px 5px rgba(0, 0, 0, 0.1), inset -3px -3px 5px #FFFFFF;
        }
        
        &:hover {
           box-shadow: ${
             props.moduleBackground === 'grayLighter'
               ? '-10px -10px 20px #FFFFFF, 10px 10px 20px #DCDCDC'
               : '-10px -10px 20px rgba(255, 255, 255, 0.8), 10px 10px 20px #DCDCDC;'
           };
          border: 5px solid ${props.theme.colors.grayLighter};
          background-color: ${props.theme.colors.grayLighter};
          color: ${props.theme.colors.primary};
          
          &::after {
            opacity: 1;
          }
        }
      `
    } else {
      return `
        background-color: transparent;
        color: ${props.theme.colors.primary};
        border-color: transparent;
        box-shadow: -10px -10px 20px #FFFFFF, 10px 10px 20px #DCDCDC;
        
        &::after {
          box-shadow: inset 3px 3px 5px #e86054, inset -3px -3px 5px #ff8f86;
        }
        
        &:hover {
          background-color: ${props.theme.colors.primary};
          color: #fff;
          box-shadow: -10px -10px 20px #FFFFFF, 10px 10px 20px rgba(255, 117, 105, 0.35);
          
          &::after {
            opacity: 1;
          }
        }
      `
    }
  }}
`

export const LinkButton = Button.withComponent(Link)
