import styled from '@emotion/styled'
// @ts-ignore
import Arrow from '../../atoms/Icons/Arrow.svg'

export const ArrowIcon = styled(Arrow)`
  width: 42px;
  height: 16px;
  transition: margin-right 0.3s;
`

export const Text = styled.span`
  font-weight: 700;
`

export const Loader = styled.div`
  display: block;
  width: 16px;
  height: 16px;

  &:after {
    content: ' ';
    display: block;
    width: 16px;
    height: 16px;
    margin: 0;
    position: relative;
    top: -2px;
    border-radius: 50%;
    border: 2px solid #fff;
    border-color: #fff transparent #fff transparent;
    animation: lds-dual-ring 1.4s linear infinite;
  }

  @keyframes lds-dual-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`
